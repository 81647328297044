@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Inter", sans-serif;
}

@layer components {
  .pageSpantitle {
    @apply lowercase text-secondarypink font-semibold text-xl leading-normal;
  }

  .pageHeaderTitle {
    @apply font-bold text-secondaryblue text-2xl leading-7 text-left md:text-center lg:text-left lg:text-5xl lg:leading-[45px] w-full max-w-xs lg:max-w-md;
  }

  .pageDescription {
    @apply text-secondaryblue text-left md:text-center lg:text-left text-sm md:text-base leading-[25px] font-normal w-full max-w-full md:max-w-md lg:max-w-sm;
  }

  .contactbuttonemail {
    @apply bg-white w-full p-4 rounded-[10px] flex text-secondaryyellow items-center justify-center gap-5 hover:shadow-md font-semibold;
  }

  .contactbuttoncall {
    @apply bg-primaryblue w-full p-4 rounded-[10px] flex text-white items-center justify-center gap-5 hover:shadow-md font-semibold;
  }

  .homeSecondSection {
    @apply bg-white p-5 rounded-[10px] relative border-[2px] first:border-secondarypink even:border-primaryblue last:border-secondaryyellow;
  }

  .homeSecondSection:first-child {
    @apply bg-secondarypink text-secondarypink;
  }

  .homeSecondSection:nth-child(2) {
    @apply bg-primaryblue text-primaryblue;
  }

  .homeSecondSection:last-child {
    @apply bg-secondaryyellow text-secondaryyellow;
  }
}

.aboutBackground {
  background: url("./assets/homeImage_2.png") no-repeat center center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
}

.slick-prev {
  left: 0px !important;
  z-index: 10 !important;
}

.slick-next {
  right: 0px !important;
}

a.glink.gt-current-lang {
  color: #ff5ac0 !important;
  font-family: "Inter", sans-serif !important;
  text-transform: capitalize !important;
}

a.glink {
  font-family: "Inter", sans-serif !important;
  text-transform: capitalize !important;
}
